import React, { useState } from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

const serverName = "https://rsvpatapi.terrencetuy.com"


function Name(props) {
  const [pageError, setPageError] = useState(false);
  const submitName = (e) => {
    if (document.getElementById("firstName").value.trim() && document.getElementById("lastName").value.trim()) {
      document.getElementById("submitButton").disabled = true;
      let url = serverName + "/guest/first/" + document.getElementById("firstName").value.trim() + "/last/" + document.getElementById("lastName").value.trim()
        fetch(url)
          .then(response => response.json())
          .then( json => {
            if (json === false) {
              setPageError('Unable to find guest with that name. Please try again.')
              document.getElementById("submitButton").disabled = false;
            } else {
              let guests = []
              for( let i = 0; i < json.length; i++ ) {
                guests.push({
                  firstName : json[i][1],
                  lastName : json[i][2],
                  plusone: json[i][6]
                })
              }
              props.setGuests(guests)
              if(guests.length > 1) {
                props.setPage('groupNotify')
              } else if (guests[0].plusone !== 'X') {
	      	props.setPage('guestNotify')
	      } else {
                props.setPage('rsvpWelcomePartyStatus')
              }
            }
          });
      }
    e.preventDefault()
  }
  
  return (
      <Container>
        <Row>

          <Col>
            <h2>Thank you for RSVPing</h2>
            <Form id="nameForm">
              { (pageError)? (<div className="alert alert-primary" role="alert"> {pageError} </div>) : (<div />) }
              <div className="question">What's your name?</div>
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="input" />
              </Form.Group>
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="input" />
              </Form.Group>
              <Button id="submitButton" variant="primary" type="submit" onClick={submitName}>
                Next
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
  )
}

function GroupNotify(props) {
  const proceed = (e) => {
    props.setPage('groupWelcomePartyStatus')
    e.preventDefault()
  }

  return(
      <Container>
        <Row>
          <Col>
            <h2 className="even">Welcome</h2>
            Pat and Jana have reserved {props.guests.length} seats for you.
            <br />
            <Button variant="primary" type="submit" onClick={proceed}>
              Next
            </Button>
          </Col>
        </Row>
      </Container>
  )
}

function GuestNotify(props) {
  const proceed = (e) => {
    props.setPage('rsvpWelcomePartyStatus')
    e.preventDefault()
  }

  return(
      <Container>
        <Row>
          <Col>
            <h2 className="even">Welcome</h2>
            Pat and Jana have reserved 2 seats for you.
            <br />
            <Button variant="primary" type="submit" onClick={proceed}>
              Next
            </Button>
          </Col>
        </Row>
      </Container>
  )
}

function RsvpStatus(props) {
  window.scrollTo(0,0)
  const [value, setValue] = useState(null);

  const handleChange = val => setValue(val);
  const back = (e) => {
    props.setPage('rsvpWelcomePartyStatus')
    e.preventDefault()
  }

  const submitStatus = (e) => {
    let guests = props.guests
    guests[0].going = value
    props.setGuests(guests)

    let nextPage = null;
    if (value === 'attending') {
      nextPage = 'dinner';
    }
    else if (value === 'not attending') {
      nextPage = 'message';
    }
  
    if (nextPage) {
      props.setPage(nextPage)
    }
    
    e.preventDefault()
  }

  return (
      <Container>
        <Row>
          <Col>
            <h2>Wedding RSVP</h2>
            <Form>
              <div className="question">How would you like to RSVP for the Wedding on 11/9?</div>
              <ToggleButtonGroup type="radio" name="rsvp-status" value={value} onChange={handleChange}>
                <ToggleButton value="attending">Will Gladly Attend</ToggleButton>
                <ToggleButton value="not attending">Must Regretfully Decline</ToggleButton>
              </ToggleButtonGroup>
              <div>
                <Button variant="primary" type="submit" onClick={back}>
                  Back
                </Button>
                <Button variant="primary" type="submit" onClick={submitStatus}>
                  Next
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
  )
}

function RsvpWelcomePartyStatus(props) {
  const [value, setValue] = useState(null);

  const handleChange = val => setValue(val);
  const back = (e) => {
    props.setPage('name')
    e.preventDefault()
  }

  const submitStatus = (e) => {
    let guests = props.guests
    guests[0].pre_party = value
    props.setGuests(guests)
    props.setPage('rsvpStatus')
    
    e.preventDefault()
  }

  return (
      <Container>
        <Row>
          <Col>
            <h2 className="even">Welcome Party</h2>
            <Form>
              <div className="question">Will you be joining us for the Welcome Party on 11/8?</div>
              <ToggleButtonGroup type="radio" name="rsvp-status" value={value} onChange={handleChange}>
                <ToggleButton value="attending">Will Gladly Attend</ToggleButton>
                <ToggleButton value="not attending">Must Regretfully Decline</ToggleButton>
              </ToggleButtonGroup>
              <div>
                <Button variant="primary" type="submit" onClick={back}>
                  Back
                </Button>
                <Button variant="primary" type="submit" onClick={submitStatus}>
                  Next
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
  )
}

function Decline(props) {
  return (
      <Container>
        <Row>
          <Col>
            <div className="thanks">
              We're sorry to hear you can't make it! <br />
              Thank you for responding.
            </div>
          </Col>
        </Row>
      </Container>
  )
}

function Dinner(props) {
  const [value, setValue] = useState(null);

  const handleChange = val => setValue(val);

  const back = (e) => {
    props.setPage('rsvpStatus')
    e.preventDefault()
  }

  const submitDinner = (e) => {
    if (value) {
      let guests = props.guests
      guests[0].dinner = value
      props.setGuests(guests)
      if (guests[0].plusone === 'X') {
        props.setPage('message')
      } else {
        props.setPage('plusOne')
      }
    }
    e.preventDefault()
  }

  return (
      <Container>
        <Row>
          <Col>
            <h2 className="even">Dinner</h2>
            <Form>
              <div className="question">
                We're so excited to have you!<br />
                What would you like for dinner?
              </div>
              <ToggleButtonGroup type="radio" name="rsvp-status" value={value} onChange={handleChange}>
                <ToggleButton value="chicken">Chicken Tuscany</ToggleButton>
                <ToggleButton value="pork">Braised Pork Chop</ToggleButton>
                <ToggleButton value="vegetarian">Eggplant Stack (Veg.)</ToggleButton>
              </ToggleButtonGroup>
              <div>
                <Button variant="primary" type="submit" onClick={back}>
                  Back
                </Button>
                <Button variant="primary" type="submit" onClick={submitDinner}>
                  Next
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
  )
}

function PlusOne(props) {
  const [value, setValue] = useState(null);
  const handleChange = val => setValue(val);

  const back = (e) => {
    props.setPage('dinner')
    e.preventDefault()
  }
  const submitPlusOne = (e) => {
    let nextPage = null;
    if (value) {
      let guests = props.guests
      guests[0].plusone = value
      props.setGuests(guests)
    }
    if (value === "yes") {
      nextPage = 'plusOneName';
    }

    if (value === "no") {
      nextPage = 'message';
    }

    if (nextPage) {
      props.setPage(nextPage)
    }
      
      e.preventDefault()
  }
  return (
      <Container>
        <Row>
          <Col>
            <h2>Plus One</h2>
            <Form>
              <div className="question">
                It looks like you were given a plus one.<br />
                Would you be bringing a guest?
              </div>
              <ToggleButtonGroup type="radio" name="rsvp-status" value={value} onChange={handleChange}>
                <ToggleButton value="yes">Yes</ToggleButton>
                <ToggleButton value="no">No</ToggleButton>
              </ToggleButtonGroup>
              <div>
                <Button variant="primary" type="submit" onClick={back}>
                  Back
                </Button>
                <Button variant="primary" type="submit" onClick={submitPlusOne}>
                  Next
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
  );
}

function PlusOneName(props) {

  const back = (e) => {
    props.setPage('plusOne')
    e.preventDefault()
  }
  const submitName = (e) => {
    let guests = props.guests
    guests[0].plusone_firstname = document.getElementById('firstName').value.trim()
    guests[0].plusone_lastname = document.getElementById('lastName').value.trim()
    props.setGuests(guests)

    props.setPage('plusOneDinner')
    e.preventDefault()
  }
  
  return (
      <Container>
        <Row>
          <Col>
            <h2 className="even question">What's your guest's name?</h2>
            <Form id="nameForm">
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="input" />
              </Form.Group>
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="input" />
              </Form.Group>
              <Button variant="primary" type="submit" onClick={back}>
                Back
              </Button>
              <Button variant="primary" type="submit" onClick={submitName}>
                Next
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
  )
}

function PlusOneDinner(props) {
  const [value, setValue] = useState(null);
  const handleChange = val => setValue(val);

  const back = (e) => {
    props.setPage('plusOneName')
    e.preventDefault()
  }

  const submitPlusOne = (e) => {
    if (value) {
      props.setPage('message')
    }
    e.preventDefault()
  }
  return (
      <Container>
        <Row>
          <Col>
            <h2>Dinner</h2>
            <Form>
              <div className="question">
                What would your guest like for dinner?
              </div>
              <ToggleButtonGroup type="radio" name="rsvp-status" value={value} onChange={handleChange}>
                <ToggleButton value="chicken">Chicken Tuscany</ToggleButton>
                <ToggleButton value="pork">Braised Pork Chop</ToggleButton>
                <ToggleButton value="vegetarian">Eggplant Stack (Veg.)</ToggleButton>
              </ToggleButtonGroup>
              <div>
                <Button variant="primary" type="submit" onClick={back}>
                  Back
                </Button>
                <Button variant="primary" type="submit" onClick={submitPlusOne}>
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
  );
}

function ThankYou() {
  return (
      <Container>
        <Row>
          <Col>
            <div className="thanks">
              Thanks so much for responding!<br />
              We'll see you in November!
            </div>
          </Col>
        </Row>
      </Container>
  )
}

function GroupRsvpStatus(props) {
  const [pageErrors, setPageErrors] = useState([]);

  const back = () => {
    props.setPage('groupWelcomePartyStatus')
  }

  const submitRsvp = (e) => {
    let guests = props.guests
    let errors = []
    let everyoneDeclined = true;
    let hasPlusOne = false
    for (let i = 0; i < guests.length; i++) {
      if (document.querySelector('input[name="rsvp-status-' + i +'"]:checked')) {
        guests[i].going = document.querySelector('input[name="rsvp-status-' + i +'"]:checked').value;
        if (guests[i].going === 'attending'){
          everyoneDeclined = false
          if (guests[i].plusone !== 'X'){
            hasPlusOne = true
          }
        }
      } else {
        errors.push( "Please fill out a response for " + guests[i].firstName + ' ' + guests[i].lastName)
      }
    }
    setPageErrors(errors)
    props.setGuests(guests)
    if (errors.length === 0) {
      if (everyoneDeclined){
        props.setPage('message')
      } else if (hasPlusOne){
        props.setPage('groupPlusOne')
      } else {
        props.setPage('groupPlusOneDinner')
      }
    }
    e.preventDefault()
  }

  return (
      <Container>
        <Row>
          <Col>
            <h2 className="even">Wedding RSVP</h2>
            <Form id="rsvpForm">
              <ul>
                { pageErrors.map( 
                  (pageError, i) => {
                    return <li key={i} className="alert alert-primary" role="alert">{pageError}</li>
                  })
                }
              </ul>
              <div className="question">How would you like to RSVP for the Wedding on 11/9?</div>
              { props.guests.map( 
                (guest, i) => {
                  return (
                    <div key={i}>
                      <label>{guest.firstName} {guest.lastName}:</label>
                      <ToggleButtonGroup type="radio" name={"rsvp-status-" + i}>
                        <ToggleButton value="attending">Will Gladly Attend</ToggleButton>
                        <ToggleButton value="not attending">Must Regretfully Decline</ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  )
                })
              }
              <Button variant="primary" type="submit" onClick={back}>
                Back
              </Button>
              <Button variant="primary" type="submit" onClick={submitRsvp}>
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
  )
}

function GroupWelcomePartyStatus(props) {
  const [pageErrors, setPageErrors] = useState([]);

  const back = () => {
    props.setPage('groupNotify')
  }

  const submitRsvp = (e) => {
    let guests = props.guests
    let errors = []
    for (let i = 0; i < guests.length; i++) {
      if (document.querySelector('input[name="rsvp-status-' + i +'"]:checked')) {
        guests[i].pre_party = document.querySelector('input[name="rsvp-status-' + i +'"]:checked').value;
      } else {
        errors.push( "Please fill out a response for " + guests[i].firstName + ' ' + guests[i].lastName)
      }
    }
    setPageErrors(errors)
    props.setGuests(guests)
    if (errors.length === 0) {
      props.setPage('groupRsvpStatus')
    }
    e.preventDefault()
  }

  return (
      <Container>
        <Row>
          <Col>
            <h2>Welcome Party</h2>
            <Form id="rsvpForm">
              <ul>
                { pageErrors.map( 
                  (pageError, i) => {
                    return <li key={i} className="alert alert-primary" role="alert">{pageError}</li>
                  })
                }
              </ul>
              <div className="question">Will you be joining us for the Welcome Party on 11/8?</div>
              { props.guests.map( 
                (guest, i) => {
                  return (
                    <div key={i}>
                      <label>{guest.firstName} {guest.lastName}:</label>
                      <ToggleButtonGroup type="radio" name={"rsvp-status-" + i}>
                        <ToggleButton value="attending">Will Gladly Attend</ToggleButton>
                        <ToggleButton value="not attending">Must Regretfully Decline</ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  )
                })
              }
              <Button variant="primary" type="submit" onClick={back}>
                Back
              </Button>
              <Button variant="primary" type="submit" onClick={submitRsvp}>
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
  )
}

function GroupPlusOne(props) {
  const [pageErrors, setPageErrors] = useState([]);

  const back = () => {
    props.setPage('groupRsvpStatus')
  }

  const submitRsvp = (e) => {
    let guests = props.guests
    let errors = []
    for (let i = 0; i < guests.length; i++) {
      if (guests[i].going === 'attending' && guests[i].plusone !== 'X') {
        if (document.querySelector('input[name="rsvp-status-' + i +'"]:checked')) {
          guests[i].plusone = document.querySelector('input[name="rsvp-status-' + i +'"]:checked').value;
        } else {
          errors.push( "Please fill out a response for " + guests[i].firstName + ' ' + guests[i].lastName)
        }
      }
    }
    setPageErrors(errors)
    props.setGuests(guests)
    if (errors.length === 0) {
      props.setPage('groupPlusOneName')
    }
    e.preventDefault()
  }

  return (
      <Container>
        <Row>
          <Col>
            <h2>Plus One</h2>
            <Form id="rsvpForm">
              <ul>
                { pageErrors.map( 
                  (pageError, i) => {
                    return <li key={i} className="alert alert-primary" role="alert">{pageError}</li>
                  })
                }
              </ul>
              <div className="question">Will there be any guests?</div>
              { props.guests.map( 
                (guest, i) => {
                  if (guest.going === 'attending' && guest.plusone !== 'X') {
                    return (
                      <div key={i}>
                        <label>{guest.firstName} {guest.lastName}:</label>
                        <ToggleButtonGroup type="radio" name={"rsvp-status-" + i}>
                          <ToggleButton value="yes">Yes</ToggleButton>
                          <ToggleButton value="no">No</ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    )
                  }
		  return null;
                })
              }
              <Button variant="primary" type="submit" onClick={back}>
                Back
              </Button>
              <Button variant="primary" type="submit" onClick={submitRsvp}>
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
  )
}

function GroupPlusOneName(props) {
  const [pageErrors, setPageErrors] = useState([]);

  const back = () => {
    props.setPage('groupPlusOne')
  }

  const submitRsvp = (e) => {
    let guests = props.guests
    let errors = []
    for (let i = 0; i < guests.length; i++) {
      if (guests[i].plusone === "yes") {
        let firstName = document.querySelector('input[name="firstName-' + i +'"]').value
        let lastName = document.querySelector('input[name="lastName-' + i +'"]').value
        if (firstName && lastName) {
          guests[i].plusone_firstname = firstName
          guests[i].plusone_lastname = lastName
        } else {
          errors.push( "Please fill out a guest for " + guests[i].firstName + ' ' + guests[i].lastName)
        }
      }
    }
    setPageErrors(errors)
    props.setGuests(guests)
    if (errors.length === 0) {
      props.setPage('groupPlusOneDinner')
    }
    e.preventDefault()
  }

  return (
      <Container>
        <Row>
          <Col>
            <h2 className="even">Guests</h2>
            <Form id="rsvpForm">
              <ul>
                { pageErrors.map( 
                  (pageError, i) => {
                    return <li key={i} className="alert alert-primary" role="alert">{pageError}</li>
                  })
                }
              </ul>
              <div className="question">Please Enter the names of your guests</div>
              { props.guests.map( 
                (guest, i) => {
                  if (guest.plusone === "yes") {
                    return (
                      <div key={i}>
                        <h4>{guest.firstName} {guest.lastName}:</h4>
                        <Form.Group controlId="firstName">
                          <label>First:</label><input className="groupplusone" name={"firstName-"+ i}/>
                        </Form.Group>
                        <Form.Group controlId="lastName">
                          <label>Last:</label><input className="groupplusone" name={"lastName-"+ i}/>
                        </Form.Group>
                      </div>
                    )
                  }
		  return null
                })
              }
              <Button variant="primary" type="submit" onClick={back}>
                Back
              </Button>
              <Button variant="primary" type="submit" onClick={submitRsvp}>
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
  )
}

function GroupPlusOneDinner(props) {
  const [pageErrors, setPageErrors] = useState([]);

  const back = () => {
    props.setPage('groupPlusOneName')
  }

  const submitRsvp = (e) => {
    let guests = props.guests
    let errors = []
    for (let i = 0; i < guests.length; i++) {
      if (guests[i].going === 'attending') {
        if (document.querySelector('input[name="rsvp-status-' + i +'"]:checked')) {
          guests[i].dinner = document.querySelector('input[name="rsvp-status-' + i +'"]:checked').value;
        } else {
          errors.push( "Please fill out a response for " + guests[i].firstName + ' ' + guests[i].lastName)
        }
      }
    }
    for (let i = 0; i < guests.length; i++) {
      if (guests[i].plusone === 'yes') {
        if (document.querySelector('input[name="plusone-status-' + i +'"]:checked')) {
          guests[i].plusone_dinner = document.querySelector('input[name="plusone-status-' + i +'"]:checked').value;
        } else {
          errors.push( "Please fill out a response for " + guests[i].plusone_firstname + ' ' + guests[i].plusone_lastname)
        }
      }
    }
    setPageErrors(errors)
    props.setGuests(guests)
    if (errors.length === 0) {
      props.setPage('groupReview')
    }
    e.preventDefault()
  }

  return (
      <Container>
        <Row>
          <Col>
            <h2>Dinner</h2>
            <Form id="rsvpForm">
              <ul>
                { pageErrors.map( 
                  (pageError, i) => {
                    return <li key={i} className="alert alert-primary" role="alert">{pageError}</li>
                  })
                }
              </ul>
              <div className="question">We're so excited to have you! What would you like for dinner?</div>
              { props.guests.map( 
                (guest, i) => {
                  if (guest.going === "attending") {
                    return (
                      <div key={i}>
                        <label>{guest.firstName} {guest.lastName}:</label>
                        <ToggleButtonGroup type="radio" name={"rsvp-status-" + i}>
                          <ToggleButton value="chicken">Chicken Tuscany</ToggleButton>
                          <ToggleButton value="pork">Braised Pork Chop</ToggleButton>
                          <ToggleButton value="vegetarian">Eggplant Stack (Veg.)</ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    )
                  }
		  return null
                })
              }
              { props.guests.map( 
                (guest, i) => {
                  if (guest.plusone === "yes") {

                    return (
                      <div key={i}>
                        <label>{guest.plusone_firstname} {guest.plusone_lastname}:</label>
                        <ToggleButtonGroup type="radio" name={"plusone-status-" + i}>
                          <ToggleButton value="chicken">Chicken Tuscany</ToggleButton>
                          <ToggleButton value="pork">Braised Pork Chop</ToggleButton>
                          <ToggleButton value="vegetarian">Eggplant Stack (Veg.)</ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    )
                  } else {
                    return null;
                  }

                })
              }
              <Button variant="primary" type="submit" onClick={back}>
                Back
              </Button>
              <Button variant="primary" type="submit" onClick={submitRsvp}>
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
  )
}

function GroupReview(props) {

  const back = () => {
    props.setPage('groupPlusOneDinner')
  }
  
  const submitRsvp = (e) => {
    props.setPage('message')
    e.preventDefault()
  }

  let guests = props.guests;
  let welcomePartyGuests = 0
  let weddingGuests = 0
  let chickenCount = 0
  let porkCount = 0
  let vegetarianCount = 0

  for (let i = 0; i < props.guests.length; i++) {
    if (guests[i].pre_party === 'attending') {
      welcomePartyGuests++;
    }
    
    if (guests[i].going === 'attending') {
      weddingGuests++;
    }

    if (guests[i].dinner === 'chicken') {
      chickenCount++;
    }

    if (guests[i].dinner === 'pork') {
      porkCount++;
    }

    if (guests[i].dinner === 'vegetarian') {
      vegetarianCount++;
    }

    if (guests[i].plusone_dinner === 'chicken') {
      chickenCount++;
    }

    if (guests[i].plusone_dinner === 'pork') {
      porkCount++;
    }

    if (guests[i].plusone_dinner === 'vegetarian') {
      vegetarianCount++;
    }
  }

  return (
      <Container>
        <Row>
          <Col>
            <h2 className="even">Review</h2>
            
            <Form id="rsvpForm">
              <div className="question">Please review your response</div>
              <div className="finalGuesetCount">Welcome party guests: {welcomePartyGuests}</div>
              <div className="finalGuesetCount">Wedding Guests: {weddingGuests}</div>
              <div>
                <h4>Dinner selections:</h4>
              </div>
                <div>
                  <div>Vegetarian: {vegetarianCount}</div>
                  <div>Chicken: {chickenCount}</div>
                  <div>Pork: {porkCount}</div>
                </div>
  
       

              <Button variant="primary" type="submit" onClick={back}>
                Back
              </Button>
              <Button variant="primary" type="submit" onClick={submitRsvp}>
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    )
}

function Message(props) {
  const [value, setValue] = useState(null);
  const handleChange = val => setValue(val);

  const back = (e) => {
    if(props.guests.length > 1){
      props.setPage('groupRsvpStatus')
    } else {
      props.setPage('rsvpStatus')
    }
    e.preventDefault()
  }

  const submitMessage = (e) => {
    let nextPage = 'decline'
    for (let i = 0; i < props.guests.length; i++) {
      if (props.guests[i].going === 'attending') {
        nextPage = 'thankYou'
      }
    }
    const url = serverName + "/guests/"
    if (document.getElementById('message').value) {
      props.guests[0].decline_message = document.getElementById('message').value
    }
    fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(props.guests)
    })
      .then(response => response.json())
      .then( json => {
        if ( json === true) {
          props.setPage(nextPage)
        }
      })
    e.preventDefault()
  }

  return (
      <Container>
        <Row>
          <Col>
            <h2>Message</h2>
            <Form>
              <div className="question">
                Would you like to send a message to the couple?
              </div>
              <Form.Control as="textarea" id="message" rows="3" />
              <div>
                <Button variant="primary" type="submit" onClick={back}>
                  Back
                </Button>
                <Button variant="primary" type="submit" onClick={submitMessage}>
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
  );

}

function DeclineMessage(props) {
  
  const [value, setValue] = useState(null);
  const handleChange = val => setValue(val);

  const back = (e) => {
    if(props.guests.length > 1){
      props.setPage('groupRsvpStatus')
    } else {
      props.setPage('rsvpStatus')
    }
    e.preventDefault()
  }

  const submitDecline = (e) => {
    const url = serverName + "/guests/"
    if (document.getElementById('message').value) {
      props.guests[0].decline_message = document.getElementById('message').value
    }
    fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(props.guests)
    })
      .then(response => response.json())
      .then( json => {
        if ( json === true) {
          props.setPage('decline')
        }
      })
    e.preventDefault()
  }

  return (
      <Container>
        <Row>
          <Col>
            <h2>Dinner</h2>
            <Form>
              <div className="question">
                So sorry you can't make it. Would you like to send a message to the couple?
              </div>
              <Form.Control as="textarea" id="message" rows="3" />
              <div>
                <Button variant="primary" type="submit" onClick={back}>
                  Back
                </Button>
                <Button variant="primary" type="submit" onClick={submitDecline}>
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
  );

}

function App() {
  const [guests, setGuests] = useState([]);

  const [page, setPage] = useState("name");

  const pages = {
    name : <Name setPage={setPage} setGuests={setGuests} />,
    rsvpStatus : <RsvpStatus setPage={setPage} guests={guests} setGuests={setGuests}/>,
    rsvpWelcomePartyStatus : <RsvpWelcomePartyStatus setPage={setPage} guests={guests} setGuests={setGuests}/>,
    decline : <Decline/>,
    dinner : <Dinner setPage={setPage} guests={guests} setGuests={setGuests}/>,
    plusOne : <PlusOne setPage={setPage} guests={guests} setGuests={setGuests}/>,
    plusOneName : <PlusOneName setPage={setPage} guests={guests} setGuests={setGuests}/>,
    plusOneDinner : <PlusOneDinner setPage={setPage} guests={guests} setGuests={setGuests}/>,
    thankYou: <ThankYou />,
    guestNotify : <GuestNotify setPage={setPage} guests={guests} setGuests={setGuests} />,
    groupNotify : <GroupNotify setPage={setPage} guests={guests} setGuests={setGuests} />,
    groupRsvpStatus : <GroupRsvpStatus setPage={setPage} guests={guests} setGuests={setGuests} />,
    groupWelcomePartyStatus : <GroupWelcomePartyStatus setPage={setPage} guests={guests} setGuests={setGuests} />,
    groupPlusOne : <GroupPlusOne setPage={setPage} guests={guests} setGuests={setGuests}/>,
    groupPlusOneName : <GroupPlusOneName setPage={setPage} guests={guests} setGuests={setGuests}/>,
    groupPlusOneDinner : <GroupPlusOneDinner setPage={setPage} guests={guests} setGuests={setGuests}/>,
    groupReview : <GroupReview setPage={setPage} guests={guests} setGuests={setGuests}/>,
    message : <Message setPage={setPage} guests={guests} setGuests={setGuests}/>,
  }
  
  return (
      <div className="App" id="app">
        {pages[page]}
      </div>
  );
}

export default App;
